@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap");

body {
  @apply font-sans;
  margin: 0;
  padding: 0;
  min-height: 100dvh;
  width: 100%;
  overflow-x: hidden;
}

/* Container principal */
.container {
  @apply w-full mx-auto px-4;
}

/* Ajustes para componentes específicos */
.search-container {
  @apply w-full max-w-md mx-auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold;
}

p {
  @apply mb-4 leading-relaxed;
}

a {
  @apply text-blue-500 hover:text-blue-700;
}

ul {
  @apply list-disc pl-5;
}

button {
  @apply cursor-pointer font-sans;
}
